define("discourse/plugins/discourse-ai/discourse/connectors/split-new-topic-title-after/ai-title-suggestion", ["exports", "@glimmer/component", "discourse/plugins/discourse-ai/discourse/components/ai-split-topic-suggester", "discourse/plugins/discourse-ai/discourse/lib/show-ai-helper", "@ember/component", "@ember/template-factory"], function (_exports, _component, _aiSplitTopicSuggester, _showAiHelper, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _AiTitleSuggestion;
  class AiTitleSuggestion extends _component.default {
    static shouldRender(outletArgs1, helper1) {
      return (0, _showAiHelper.showPostAIHelper)(outletArgs1, helper1);
    }
  }
  _exports.default = AiTitleSuggestion;
  _AiTitleSuggestion = AiTitleSuggestion;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <AiSplitTopicSuggester
        @selectedPosts={{@outletArgs.selectedPosts}}
        @mode="suggest_title"
        @updateAction={{@outletArgs.updateTopicName}}
      />
    
  */
  {
    "id": "obhR2Q8M",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@selectedPosts\",\"@mode\",\"@updateAction\"],[[30,1,[\"selectedPosts\"]],\"suggest_title\",[30,1,[\"updateTopicName\"]]]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/split-new-topic-title-after/ai-title-suggestion.js",
    "scope": () => [_aiSplitTopicSuggester.default],
    "isStrictMode": true
  }), _AiTitleSuggestion);
});
define("discourse/plugins/discourse-ai/discourse/admin/models/ai-llm", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiLlm extends _rest.default {
    createProperties() {
      return this.getProperties("display_name", "name", "provider", "tokenizer", "max_prompt_tokens");
    }
    updateProperties() {
      const attrs = this.createProperties();
      attrs.id = this.id;
      return attrs;
    }
  }
  _exports.default = AiLlm;
});